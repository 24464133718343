<template>
    <div class="calendar" style="padding: 20px;">
        <div class="q-gutter-md row justify-start q-carousel__navigation-inner row items-center">
            <p style="color: #564254; font-size: 22px; font-weight: bold;">Calendar</p>
        </div>
        <div style="width: 100%; display: flex; justify-content: center; flex-wrap: wrap;"> 
            <vc-calendar :attributes='attributes' style="flex-shrink: 0;" />
            <div class="q-pa-md" style="width: 80%; flex-shrink: 0; margin-bottom: 20px;">
                <p style="color: #564254; font-size: 20px; font-weight: bold;">Upcoming Holidays</p>
                <div v-for="item in holidays" :key="item.id">
                    <q-list bordered separator>
                        <q-item v-ripple>
                            <q-item-section>
                                <q-item-label style="font-size: 16px; font-weight: bolder;">{{ item.holiday }} </q-item-label>
                                <q-item-label style="font-weight: bold;" caption>{{ formatDate(item.holiday_date) }}</q-item-label>
                                <!-- <q-item-label caption>{{ item.holiday_type }} Holiday</q-item-label> -->
                            </q-item-section>
                        </q-item>
                    

                    <!-- <q-item clickable v-ripple>
                        <q-item-section>
                        <q-item-label overline>OVERLINE</q-item-label>
                        <q-item-label>Item with overline</q-item-label>
                        </q-item-section>
                    </q-item> -->
                    </q-list>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Calendar',

    data() {
        return {
            // incId: holidays.length,
			holidays: []
        };
    },

    created() {
        this.getHolidays();
        this.filterUpcomingHolidays();
    },

    computed: {
		attributes() {
            return [
                // Attributes for holidays
                ...this.holidays.map(holiday => ({
                    dates: this.convertDate(holiday.holiday_date),
                    dot: {
                        color: holiday.color,
                        // class: holiday.isComplete ? 'opacity-75' : '',
                    },
                    popover: {
                        label: holiday.holiday,
                    },
                    // customData: holiday,
                })),
            ];
		},
	},

    methods: {
        async getHolidays() {
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get(`/timekeeping/holiday/${new Date().getFullYear()}`)
			.then((res) => {
				this.holidays = res.data.data;
				setTimeout(() => {
					this.spinner = false
				},500)
			})
		},

        // Converts sample date: '2024-12-25' to 'December 25, 2024'
        formatDate(dateStr) {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return new Date(dateStr).toLocaleDateString(undefined, options);
        },

        // Converts sample date: '2024-12-25' to 2024, 12, 25
        // Convertion is needed for v-calendar's attributes.dates
        convertDate(date) {
            const [year, month, day] = date.split('-').map(Number);
            const dateObject = new Date(year, month - 1, day);
            return dateObject;
        },

        //  TODO: Filter upcoming holidays in the backend
        filterUpcomingHolidays() {
            const today = new Date();
            console.log('date:' + this.holidays.filter(holiday => this.convertDate(holiday.holiday_date) >= today))
            // return date;
        }
    },
};
</script>

<style scoped>
</style>