<template>
  <!-- <div class="full-width row">asdasd -->
  <q-dialog v-model="alert" persistent transition-show="jump-left" transition-hide="jump-right" >
    <div class="q-mr-xl bg-white" style="height: auto; width: 800px; max-width: 800px;">
        <q-card class="my-card">
        <q-card-section class="q-ma-none q-pa-none bg-grey-8 text-white" style="display: flex; justify-content: center; align-items: center;">
          <q-item class="q-pa-md">
            <q-item-section avatar style="display: flex; justify-content: center; align-items: center;">
              <q-avatar size="100px">
                <img :src="apiUrl + userData.image">
              </q-avatar>
              <div style="display: flex; justify-content: center; align-items: center; margin-top: 5px;">
								<q-btn color="primary" style="width: 100%;" @click="getImage()" >Change Photo</q-btn>
								<q-file style="display: none;" ref="file" clearable filled @change="handleFileChange" v-model="uploadedImage" label="Label"></q-file>
							</div>
            </q-item-section>
            <q-item-section style="width: 100%;">
              <q-item-label class="text-h5" >{{ fullname }}</q-item-label>
              <q-item-label class="text-white" caption lines="1">{{ position }}</q-item-label>
              <q-item-label class="text-white" caption lines="1">Time zone: {{ timeZone }}</q-item-label>
            </q-item-section>
            <q-space />
            <q-btn flat round dense v-close-popup ><i class="fas fa-times"></i></q-btn>
          </q-item>
        </q-card-section>
        <q-card-section class="q-ma-none q-pt-lg q-pl-lg q-pr-lg">

            <div class="row">
                <div class="col-4 q-pr-sm">
                    <q-field :value="employeeID" color="grey-8" label-color="green" label="Employee ID" stack-label>
                      <template v-slot:before>
                        <i class="fas fa-key" style="width:30px;"></i>
                      </template>
                      <template v-slot:control>
                        <div class="self-center full-width no-outline" tabindex="0">{{employeeID}}</div>
                      </template>
                    </q-field>
                </div>
                <div class="col-4 q-pl-sm">
                    <q-field :value="dateHired" color="grey-8" label-color="green" label="Date Hired" stack-label>
                      <template v-slot:before>
                        <i class="fas fa-calendar-alt" style="width:30px;"></i>
                      </template>
                      <template v-slot:control>
                        <div class="self-center full-width no-outline" tabindex="0">{{dateHired}}</div>
                      </template>
                    </q-field>
                </div>
                <div class="col-4 q-pl-sm">
                    <q-field :value="department" color="grey-8" label-color="green" label="Department" stack-label>
                      <template v-slot:before>
                        <i class="fas fa-building" style="width:30px;"></i>
                      </template>
                      <template v-slot:control>
                        <div class="self-center full-width no-outline" tabindex="0">{{department}}</div>
                      </template>
                    </q-field>
                </div>
            </div>
            <q-form ref="vForm" @submit="updateProfile" class="col-gutter-y-sm">
            <div class="row">
                <div class="col-6 q-pl-sm">
                    <q-input color="purple-12" v-model="email" label="Email Address" label-color="green" lazy-rules :rules="[formRules.required]">
                      <template v-slot:prepend>
                        <i class="fas fa-at" style="width:30px;"></i>
                      </template>
                    </q-input>
                </div>
                <div class="col-6 q-pl-sm">
                    <q-input color="purple-12" v-model="mobile" label="Mobile" label-color="green" lazy-rules :rules="[formRules.required]">
                      <template v-slot:prepend>
                         <i class="fas fa-mobile" style="width:30px;"></i>
                      </template>
                    </q-input>
                </div>
            </div>
            <div class="col-12 q-col-gutter-md">
            <q-input color="purple-12" v-model="currentAddress" label="Current Address" label-color="green" :rules="[formRules.required]">
              <template v-slot:prepend>
                <i class="fas fa-street-view" style="width:30px;"></i>
              </template>
            </q-input>
            <q-input color="purple-12" v-model="provincialAddress" label="Provincial Address" label-color="green" :rules="[formRules.required]">
              <template v-slot:prepend>
                <i class="fas fa-map-marker-alt" style="width:30px;"></i>
              </template>
            </q-input>
            <q-input color="purple-12" v-model="contactPerson" label="Contact Person Incase of Emergency" label-color="green" :rules="[formRules.required]">
              <template v-slot:prepend>
                <i class="fas fa-address-card" style="width:30px;"></i>
              </template>
            </q-input>
            <q-input color="purple-12" v-model="contactPersonRelation" label="Relationship" label-color="green" :rules="[formRules.required]">
              <template v-slot:prepend>
                <i class="fas fa-people-arrows" style="width:30px;"></i>
              </template>
            </q-input>
            <q-input color="purple-12" v-model="contactPersonMobile" label="Contact Person Mobile Number" label-color="green" :rules="[formRules.required]">
              <template v-slot:prepend>
                <i class="fas fa-mobile-alt" style="width:30px;"></i>
              </template>
            </q-input>

            </div>
            <q-btn  type="submit" color="orange" text-color="white" style="height: 50px; width: 100%; margin-top: 20px;" label="Update" />
            </q-form>
        </q-card-section>
      </q-card>
    </div>
  </q-dialog>
  <!-- </div> -->
</template>
<script>
import axios from 'axios';
import vueCookies from 'vue-cookies';
export default {
  name: 'ChangePassword',
  data: () => ({
    alert: false,
    fullname: null,
    position: null,
    employeeID: null,
    dateHired: null,
    department: null,
    username: null,
    status: null,

    email: null,
    mobile:null,
    currentAddress:null,
    provincialAddress:null,
    contactPerson:null,
    contactPersonRelation:null,
    contactPersonMobile:null,
    timeZone: null,
    apiUrl: process.env.VUE_APP_API_URL,
    uploadedImage: null,
  }),

  created() {
    this.eventHub.$on('myProfile', this.myProfile);
    this.displayData()
    this.getTimeZone()
  },
  methods: {
    myProfile: function(payload) {
      this.alert = payload.status
    },
    closeModal: function() {
      this.alert = false
    },

    displayData: function() {
      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get(`/employee/profile`)
        .then((res) => {

          this.employeeID = res.data.data[0].employee_id
          this.position = res.data.data[0].position
          this.fullname = res.data.data[0].fullname
          this.username = res.data.data[0].username
          this.email = res.data.data[0].email
          this.department = res.data.data[0].department
          this.dateHired = res.data.data[0].date_hired
          this.status = res.data.data[0].status

          this.mobile = res.data.data[0].mobile
          this.currentAddress = res.data.data[0].currentAddress
          this.provincialAddress = res.data.data[0].provincialAddress
          this.contactPerson = res.data.data[0].contactPerson
          this.contactPersonRelation = res.data.data[0].contactPersonRelation
          this.contactPersonMobile = res.data.data[0].contactPersonMobile
        })
    },

    updateProfile: function() {
      let formData = new FormData();
      formData.append("email", this.email);
      formData.append("mobile", this.mobile);
      formData.append("currentAddress", this.currentAddress);
      formData.append("provincialAddress", this.provincialAddress);
      formData.append("contactPerson", this.contactPerson);
      formData.append("contactPersonRelation", this.contactPersonRelation);
      formData.append("contactPersonMobile", this.contactPersonMobile);
      formData.append("image", this.uploadedImage);

      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }).post('/employee/profile/update', formData).then((res) => {
          setTimeout(() => {
            this.$q.notify({
              color: 'green-7',
              textColor: 'white',
              icon: 'fa fa-check',
              message: 'Profile Updated!'
            });
          }, 1000);

        });
    },

    async getTimeZone() {
      try {
        axios.create({
            baseURL: this.apiUrl,
            headers: {
              'Authorization': `Bearer ${this.token}`
            }})
            .get('/current-time')
            .then((res) => { this.timeZone = res.data.time_zone;
              // console.log('response:' + res.data.current_time)
            })
      } catch (error) {
        console.error('Error fetching time:', error);
        this.currentTime = 'Error fetching time'; // Handle error scenario
      }
    },
    
    getImage(){
      this.$refs.file.pickFiles();  
    },

    handleFileChange(file) {
        if (file) {
          this.uploadedImage(file);
        }
    },

  }
}
</script>
<style>
</style>
