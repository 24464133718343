import Vue from 'vue'
import App from './App.vue'
import './quasar'
import mixins from './mixins'
import router from './router'
import VuePageTransition from 'vue-page-transition'
//import VueSocketIO from 'vue-socket.io'
//import VueChatScroll from 'vue-chat-scroll'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import './index.css'
import VCalendar from 'v-calendar';

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc',
});

Vue.use(VuePageTransition)
Vue.mixin(mixins)

Vue.config.productionTip = false

//Vue.use(new VueSocketIO({
  //debug: true,
  //connection: 'https://hris.sgccserver02.com:9001',
  //connection: 'http://localhost:3000',
  //connection: 'https://'+window.location.hostname+':3000',
  // vuex: {
  //     store,
  //     actionPrefix: 'SOCKET_',
  //     mutationPrefix: 'SOCKET_'
  // },
//}))

//Vue.use(VueChatScroll)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
