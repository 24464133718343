<template>
    <div class="q-pa-md">
        <div class="q-pa-md">
			<div class="row">
				<div class="row justify q-banner--top-padding q-pa-md">
					<q-select v-if="isAdmin" filled v-model="searchKey" use-input input-debounce="0" label="Search Employee"
			        :options="employeeList" @filter="filterFn" style="width: 550px" behavior="dialog" class="caps"
			      	>
			        <template v-slot:no-option>
			          <q-item>
			            <q-item-section class="text-grey">
			              No results
			            </q-item-section>
			          </q-item>
			        </template>
			      </q-select>
				</div>
                <div class="row justify q-banner--top-padding q-pa-md">
					<q-select v-model="year" :options="yearOptions"  filled label="Select Year" class="caps" style="width:200px"/>
				</div>
                <div class="row justify q-banner--top-padding q-pa-md">
					 <q-btn color="primary" style="color: red;" label="Show Details" @click="showDetails" />
				</div>
			</div>
		</div>
        <div class="col-12 col-lg-12 col-xs-12 col-md-6 q-mb-md q-pr-xl">
            <div class="q-pa-md">
                <q-table title="SSS Employee Contribution" :data="sssData" :columns="columns" row-key="id" :pagination.sync="pagination" >
                    <template v-slot:body="props">
                        <q-tr slot="body" :props="props" @click="selectedRow(props)">
                            <q-td key="months" :props="props">{{ props.row.months }}</q-td>
                            <q-td key="monthlyBasic" :props="props">{{ props.row.monthlyBasic }}</q-td>
                            <q-td key="fcSSSContri" :props="props">{{ props.row.fcSSSContri }}</q-td>
                            <q-td key="scSSSContri" :props="props">{{ props.row.scSSSContri }}</q-td>
                            <q-td key="totalSSSContri" :props="props">{{ props.row.totalSSSContri }}</q-td>
                            <q-td key="fcBasic" :props="props">{{ props.row.fcBasic }}</q-td>
                            <q-td key="scBasic" :props="props">{{ props.row.scBasic }}</q-td>
                            <q-td key="totalBasic" :props="props">{{ props.row.totalBasic }}</q-td>
                        </q-tr>
                    </template>
                    <template v-slot:no-data="">
                        <div class="full-width row flex-center text-red q-gutter-sm">
                        <i class="fas fa-exclamation-triangle"></i>
                        <span>
                          No Result Found!
                        </span>
                        </div>
                </template>
                </q-table>
              </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';
import vueCookies from 'vue-cookies';

export default {
	data () {
		return {
      isAdmin: false,
			spinner:false,
			empID:'',

			searchKey:'',
			employeeList:[],

            year:'',
            yearOptions:[],

            pagination: {
              rowsPerPage: 30
            },

            columns: [
                { name: 'months', align: 'center', label: 'MONTHS', field: 'months', sortable: true},
                { name: 'monthlyBasic', align: 'center', label: 'MONTHLY BASIC PAY', field: 'monthlyBasic', sortable: true},
      		    { name: 'fcSSSContri', align: 'center', label: '1ST CUTOFF SSS CONTRI', field: 'fcSSSContri', sortable: true},
      		    { name: 'scSSSContri', align: 'center', label: '2ND CUTOFF SSS CONTRI', field: 'scSSSContri', sortable: true},
                { name: 'totalSSSContri', align: 'center', label: 'TOTAL SSS CONTRI', field: 'totalSSSContri', sortable: true},
      		    { name: 'fcBasic', align: 'center', label: '1ST CUTOFF BASIC PAY', field: 'fcBasic', sortable: true},
      		    { name: 'scBasic', align: 'center', label: '2ND CUTOFF BASIC PAY', field: 'scBasic', sortable: true},
      		    { name: 'totalBasic', align: 'center', label: 'TOTAL BASIC PAY', field: 'totalBasic', sortable: true},
            ],
            sssData:[],
		}
	},

	created(){
		this.getListOfEmployees()
    this.yearsList()

    if(vueCookies.get('sgcchris').user.role_id === 1){
      this.isAdmin = true;
    }else{
      this.showDetails()
    }
	},

	methods: {
		filterFn (val, update) {
	     	if (val === '') {
		        update(() => {
		          this.employeeList = this.employeeOptions
		        })
	        	return
	      	}
	      update(() => {
	        	const needle = val.toLowerCase()
	        	this.employeeList = this.employeeOptions.filter(emp => emp.toLowerCase().indexOf(needle) > -1)
	      })
	  	},

		getListOfEmployees: function() {
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get(`/employee/list`)
			.then((res) => {
				this.employeeOptions = res.data.data;
			})

		},

        showDetails:function(){
            this.$q.loading.show({
                spinnerColor: 'yellow',
                spinnerSize: 140,
                backgroundColor: 'purple',
                message: 'Generating in process...',
                messageColor: 'black'
            })
            var employeeID = this.searchKey.substr(0, this.searchKey.indexOf('|'));
            let formData = new FormData();

            if(this.isAdmin){
              formData.append("empID", employeeID);
              formData.append("year", this.year);
            }else{
                employeeID = vueCookies.get('sgcchris').user.employee_id;
                this.year = this.year == '' ? new Date().getFullYear() : this.year;
                formData.append("empID", employeeID);
                formData.append("year", this.year);
            }
            
            axios.create({
                baseURL: this.apiUrl,
                headers: { 'Authorization': `Bearer ${this.token}` }
              }).post(`/backend/sss/contribution`, formData)
              .then((res) => {
                    this.sssData = res.data.data
                    this.timer = setTimeout(() => {
                        this.$q.loading.hide()
                        this.timer = void 0
                    }, 1000)
              });
        },

        yearsList : function(){
            axios.create({
                baseURL : this.apiUrl,
                headers : {'Authorization' : `Bearer ${this.token}`}
            }).post(`/timekeeping/years/list`)
            .then((res) => {
                this.yearOptions = res.data.data
            });
        },

        beforeDestroy () {
            if (this.timer !== void 0) {
              clearTimeout(this.timer)
              this.$q.loading.hide()
            }
        }
	}
}
</script>
