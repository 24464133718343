<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row">
      <div class="col-12 q-mb-md right">
        <h4 class="q-ma-none">For Review Employees</h4><br />
        <div class="row">
          <!-- <div class="row justify q-banner--top-padding">
            <q-btn outline color="green" label="Download Template" @click="getTemplate" v-show="canDownload"/>
            <q-btn outline color="red" label="Import Employee" @click="importEmployees" v-show="canImport"></q-btn>
            <q-btn outline color="green" label="Export Employee" @click="downloadModal" v-show="canExport"></q-btn>
          </div> -->
          <div class="row justify-end q-carousel__navigation-inner">
            <q-select v-model="location" :options="locationOptions" label="LOCATION" @input="getEmployees" style="width: 200px" tabindex="0" />
            <q-select v-model="department" :options="departments" option-value="id" option-label="name"  emit-value map-options label="DEPARTMENT" @input="getEmployees" style="width: 200px" />
          </div>
        </div>
      </div>
      <div class="col-12 q-mb-md">
        <div class="col-12 q-mb-md">
            <q-table class="my-sticky-column-table" :dense="$q.screen.lt.md" :grid="$q.screen.xs" title="Employees"
            :data="employees"
            :columns="columns"
            row-key="id" separator="cell"
            no-data-label="I didn't find anything for you!"
            :pagination.sync="pagination"
            :loading="loading"
            :filter="filter"
            @request="onRequest"
            binary-state-sort>
            <template v-slot:body="props">
              <q-tr slot="body" :props="props">
                <q-td key="photo" :props="props">
                  <q-avatar>
                    <img :src="apiUrl + userData.image">
                  </q-avatar>
                </q-td>
                <q-td key="companyname" :props="props">{{ props.row.companyname }}</q-td>
                <q-td key="location" :props="props">{{ props.row.location }}</q-td>
                <q-td key="employee_id" :props="props">{{ props.row.employee_id }}</q-td>
                <q-td key="firstname" :props="props">{{ props.row.firstname }}</q-td>
                <q-td key="middlename" :props="props">{{ props.row.middlename }}</q-td>
                <q-td key="lastname" :props="props">{{ props.row.lastname }}</q-td>
                <q-td key="position" :props="props">{{ props.row.position }}</q-td>
                <q-td key="account" :props="props">{{ props.row.account }}</q-td>
                <q-td key="is_active" :props="props">
                  <q-btn  size="xs" :color="props.row.is_active == 'active' ? 'green': props.row.is_active == 'hold' ? 'orange' : 'red' " rounded :label="props.row.is_active"/>
                </q-td>

                <q-td key="action" :props="props" >
                  <div>
                    <q-btn round outline color="red" @click="selectedRow(props)" v-show="canEdit">
                      <i class="fas fa-user-edit"></i>
                      <q-tooltip anchor="top middle" content-class="bg-red" self="bottom middle" :offset="[10, 10]" >
                        <strong>Edit 201 File</strong>
                      </q-tooltip>
                    </q-btn>
                    <q-btn round outline color="green" @click="clickReviewEmployee(props)">
                      <i class="fas fa-check"></i>
                      <q-tooltip anchor="top middle" content-class="bg-green" self="bottom middle" :offset="[10, 10]">
                        <strong>Accept</strong>
                      </q-tooltip>
                    </q-btn>
                  </div>
                </q-td>
              </q-tr>
            </template>
            <template v-slot:no-data="">
              <div class="full-width row flex-center text-red q-gutter-sm">
                <i class="fas fa-exclamation-triangle"></i>
                <span>The filter didn't uncover any results!</span>
              </div>
            </template>
            <template v-slot:loading>
                <q-inner-loading showing>
                    <q-spinner-hourglass  size="50px" color="cyan" />
                </q-inner-loading>
            </template>
            <template v-slot:top-right>
              <q-input dense v-model="filter" placeholder="Search Employee">
                <i class="fas fa-search" slot="append"></i>
              </q-input>
            </template>
          </q-table>
          <q-dialog v-model="spinner" persistent>
            <q-card class="my-card transparent no-shadow">
              <q-card-section>
                <q-spinner-gears color="cyan" size="5.5em" />
              </q-card-section>
            </q-card>
          </q-dialog>

          <q-dialog v-model="reviewEmployee" persistent>
            <q-card>
              <q-card-section class="row q-pa-sm bg-indigo-6 text-white">
                <q-icon size="1.5em" name="fa fa-question-circle" />
                <span class="q-pl-sm">Review Employee</span>
              </q-card-section>
              <q-card-section class="row items-center">
                <span class="q-ml-sm">Are you sure you want to accept this employee?</span>
              </q-card-section>

              <q-card-actions align="right">
                <q-btn flat label="No" color="negative" v-close-popup />
                <q-btn flat label="Yes" color="primary"  @click="acceptEmployee" v-close-popup />
              </q-card-actions>
            </q-card>
          </q-dialog>

        </div>
        <EditDialog></EditDialog>
        <ImportDialog></ImportDialog>
        <ChangeStatus></ChangeStatus>
        <CareerUpdate></CareerUpdate>
        <FilterExport></FilterExport>
        <!-- <MBFDialog></MBFDialog> -->
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';
import EditDialog from './EditModal';
import ImportDialog from './ImportModal';
import ChangeStatus from './ChangeStatusModal';
import CareerUpdate from './CareerUpdate';
import FilterExport from './FilterExportDialog';
// import MBFDialog from './MBFDialog';
export default {
  name: 'ListEmployee',
  components: {
    "EditDialog": EditDialog,
    "ImportDialog": ImportDialog,
    "ChangeStatus": ChangeStatus,
    "CareerUpdate": CareerUpdate,
    "FilterExport": FilterExport,
    // "MBFDialog": MBFDialog,
  },
  data() {
    return {
        filter: '',
        loading: false,
        pagination: {
            page: 1,
            rowsPerPage: 15,
            rowsNumber: 10
        },

      canDownload:false,
      canImport:false,
      canExport:false,
      canEdit:false,
      canUpdateCareer:false,

      canDownloadValue:1,
      canImportValue:2,
      canEmportValue:3,
      canEditValue:4,
      canUpdateCareerValue:5,
      // canMBFValue:6,
      // canMBFValue:6,

      exportModaL:false,
      importfile: false,
      template: null,
      status: 'ALL',
      department: 0,
      location:'ALL',
      spinner: false,

      reviewEmployee: false,
      reviewEmployeeDetails: [],

      columns: [
      { name: 'photo', align: 'left', label: 'Photo', field: '', sortable: true },
      { name: 'companyname', align: 'center', label: 'Company', field: 'companyname', sortable: true },
      { name: 'location', align: 'center', label: 'Location', field: 'location', sortable: true },
      { name: 'employee_id', align: 'center', label: 'Employee ID', field: 'employee_id', sortable: true },
      { name: 'firstname', align: 'center', label: 'First Name', field: 'firstname', sortable: true },
      { name: 'middlename', align: 'center', label: 'Middle Name', field: 'middlename', sortable: true },
      { name: 'lastname', align: 'center', label: 'Last Name', field: 'lastname', sortable: true },
      { name: 'position', align: 'center', label: 'Position', field: 'position', sortable: true },
      { name: 'account', align: 'center', label: 'Account', field: 'account', sortable: true },
      { name: 'is_active', align: 'center', label: 'Status', field: 'is_active', sortable: true },
      { name: 'action', align: 'center', label: 'Action', field: '', sortable: true },
      ],
      locationOptions:[],
      employees: [],
      statuses: [],
      departments: [],

    }

  },

  created() {
    this.getDropdownProperties()
    this.getEmployees()
    this.autoHoldEmployee()
    this.autoCareerUpdate()
    this.eventHub.$on('refreshEmployeeList', this.getEmployees);
    this.getMyPermission()

  },


  methods: {
      //start if server side
      onRequest (props) {

        const { page, rowsPerPage } = props.pagination


        const filter = props.filter
        this.loading = true
        setTimeout(() => {
          this.getRowsNumberCount(filter).then(total => {
              this.pagination.rowsNumber = total
          })
          const fetchCount = rowsPerPage === 0 ? this.pagination.rowsNumber : rowsPerPage
          const startRow = (page - 1) * rowsPerPage
          this.fetchFromServer(startRow, fetchCount, filter)
          this.pagination.page = page
          this.pagination.rowsPerPage = rowsPerPage
        }, 1500)
      },

      fetchFromServer (startRow, count, filter) {
          axios.get(this.apiUrl + '/employees/for-review', {
              headers: { 'Authorization': `Bearer ${this.token}` },
              params: {
                status:'ACTIVE',
                location: this.location,
                department: this.department,
                roleID: this.userData.role_id,
                start: startRow,
                countPerPage: count,
                filter: filter,
              }
          }).then((response) => {
              this.employees = response.data.data
              this.loading = false
          }).catch((error) => {
              console.log(error);
          })
     },

      async getRowsNumberCount (filter) {
          console.log(this.department)
         let response = await axios.get(this.apiUrl + '/employees/for-review/total', {
             headers: { 'Authorization': `Bearer ${this.token}` },
             params: {
                status:'ACTIVE',
                location: this.location,
                department: this.department,
                roleID: this.userData.role_id,
                filter: filter,
             }
         })
         return response.data
      },

      ///end of server side
    clickReviewEmployee(emp){
      console.log(emp.row)
      this.reviewEmployeeDetails = emp.row;
      this.reviewEmployee = true;
    },

    acceptEmployee() {
      console.log(this.reviewEmployeeDetails);
      let formData = new FormData();
      formData.append('form_data', JSON.stringify(this.reviewEmployeeDetails));

      console.log("upload new hired application: ", this.form_approved);
      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }).post('/employees/accept-employee', formData).then((res) => {
        setTimeout(() => {
          console.log(res);
          this.getEmployees();
        }, 1000);
      });
    },

    getEmployees: function() {
         this.onRequest({pagination:this.pagination,filter:''})
    },

    selectedRow(emp) {
      this.eventHub.$emit('EditEmployee', { stat: true, employee: emp });
    },

    careerUpdate(emp) {
      this.eventHub.$emit('CareerUpdates', { stat: true, employee: emp });
    },

    MBFUpdate(emp) {
      this.eventHub.$emit('MBFUpdate', { stat: true, employee: emp });
    },

  getTemplate: function() {
    axios.create({
      baseURL: this.apiUrl,
      headers: {
        'Authorization': `Bearer ${this.token}`
      }
    })
    .get('/employee/template/download')
    .then((res) => {
      this.downloadTemplate(res.data.data.employee_template)
    })
  },

  downloadTemplate: function(url) {
    axios({
      url: this.apiUrl + '/' + url,
      method: 'GET',
      responseType: 'arraybuffer',
    }).then((response) => {
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', 'EmployeeTemplate.csv');
      document.body.appendChild(fileLink);

      fileLink.click();
    });
  },

  importEmployees: function() {
    this.eventHub.$emit('importFileDialog', { status: true });
  },

  changeStatus: function(status, obj) {
    this.eventHub.$emit('changeEmpStatus', { status: status, emp: obj });
  },

  getDropdownProperties: function() {
    axios.create({
      baseURL: this.apiUrl,
      headers: { 'Authorization': `Bearer ${this.token}` }
    }).get('/employee/dropdown/properties')
    .then((res) => {
      this.paymentmode = res.data.data.paymentMode
      this.payingbank = res.data.data.bank
      this.payclass = res.data.data.payClass
      this.gender = res.data.data.gender
      this.locationOptions = res.data.data.location
      this.locationOptions.unshift('ALL')
      this.statuses = res.data.data.status
      this.statuses.unshift('ALL')
      this.departments = res.data.data.department
      this.departments.unshift({'id':0,'name':'ALL'})
    });
  },

  autoCareerUpdate:function(){
    axios.create({
      baseURL: this.apiUrl,
      headers: { 'Authorization': `Bearer ${this.token}` }
    }).get('/employee/check/careerupdate')
    .then((res) => {
      console.log(res.data.msg)
    });
  },

  autoHoldEmployee: function() {
    axios.create({
      baseURL: this.apiUrl,
      headers: { 'Authorization': `Bearer ${this.token}` }
    }).get('/employee/hold/status')
    .then((res) => {
      console.log(res.data.message)
    });
  },

  autoActivateEmployee: function(){
    axios.create({
      baseURL: this.apiUrl,
      headers: { 'Authorization': `Bearer ${this.token}` }
    }).get('/employee/activate/status')
    .then((res) => {
      console.log(res.data.message)
    });
  },

  downloadModal:function(){
    this.eventHub.$emit('FilterOptions', {status: true});
  },

  getMyPermission:function(){
    //console.log(this.userData.id)
    axios.create({
      baseURL: this.apiUrl,
      headers: { 'Authorization': `Bearer ${this.token}` }
    }).get(`/admin/role/access/${this.userData.id}`)
    .then((res) => {
      var access= res.data.userAccess.moduleAction_id.split (",").map(Number)

      var dlResult = access.includes(this.canDownloadValue);
      if(dlResult){
        this.canDownload = true
      }else{
        this.canDownload = false
      }

      var imResult = access.includes(this.canImportValue);
      if(imResult){
        this.canImport = true
      }else{
        this.canImport = false
      }

      var exResult = access.includes(this.canEmportValue);
      if(exResult){
        this.canExport = true
      }else{
        this.canExport = false
      }

      var edResult = access.includes(this.canEditValue);
      if(edResult){
        this.canEdit = true
      }else{
        this.canEdit = false
      }

      var cuResult = access.includes(this.canUpdateCareerValue);
      if(cuResult){
        this.canUpdateCareer = true
      }else{
        this.canUpdateCareer = false
      }

      var mbfResult = access.includes(this.canMBFValue);
      if(mbfResult){
        this.canMBF = true
      }else{
        this.canMBF = false
      }

    });
  }
}
}
</script>
<style lang="css" scoped>
</style>
