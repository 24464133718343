<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row">
      <div class="row q-mb-md full-width">
        <div class="full-width text-h4" style="height: 40px;">
          Endorse Application List
          <q-chip square class="q-ml-sm text-bold" size="md" color="blue" text-color="white">
            <q-avatar font-size="14px" color="blue-grey-8" text-color="white" style="width:1.7em">{{ applicationCounts }}</q-avatar>
            Applications
          </q-chip>
        </div>
      </div>
      <div class="full-width row justify-center q-ma-md">
        <q-card bordered class="my-card col-12 q-pa-md">
          <q-card-section class="q-pa-none q-ma-none q-mt-sm">
            <div class="row">
              <div class="col-12">
                <q-table flat :data="applicationsData" :columns="ResultColumns" :pagination.sync="Pagination" :filter="filter" row-key="id" dense
                virtual-scroll :virtual-scroll-item-size="48" style="max-height: 600px">
                  <template v-slot:top-right>
                    <q-input borderless dense debounce="300" v-model="filter" placeholder="Search" outlined>
                      <template v-slot:append>
                        <q-icon name="fas fa-search" />
                      </template>
                    </q-input>
                  </template>
                  <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer" >
                    <q-td key="action" style="text-align:center;">
                      <!-- <q-btn color="green" rounded size="sm" text-color="white" label="Finalize" v-if="props.row.status == 'complete'" @click="doneApplication(props.row.id)" />
                      <q-btn color="primary" rounded size="sm" text-color="white" label="Edit" v-if="props.row.status == 'done'" @click="openModal(props.row.id)" /> -->
                      <q-btn color="green" rounded size="sm" text-color="white" label="Finalize" v-if="props.row.status == 'complete'" @click="openModal(props.row.id)" />  <!-- 01132023 -->
                    </q-td>

                    <q-td key="application_status" >
                        <q-badge outline color="primary" label="For Finalization" v-if="props.row.status == 'complete'" />
                        <q-badge outline color="green" label="Done" v-if="props.row.status == 'done'"/>
                    </q-td>
                    <q-td key="employee_id">{{ props.row.employee_id }}</q-td>
                    <!-- <q-td key="applicant_id">{{ props.row.applicant_id }}</q-td> -->
                    <q-td key="firstname" >{{ props.row.ad_firstname }}</q-td>
                    <q-td key="middlename" >{{ props.row.ad_middlename }}</q-td>
                    <q-td key="lastname" >{{ props.row.ad_lastname }}</q-td>
                    <q-td key="mobile" >{{ props.row.ad_mobile_number }}</q-td>
                    <q-td key="email" >{{ props.row.ad_email }}</q-td>
                    <q-td key="presaddress" >{{ props.row.ad_presaddress }}</q-td>
                    <q-td key="prescity" >{{ props.row.ad_prescity }}</q-td>
                    <q-td key="civil_status" >{{ props.row.ad_civil_status }}</q-td>
                    <q-td key="gender" >{{ props.row.ad_gender }}</q-td>
                    <q-td key="date_of_birth" >{{ props.row.ad_date_of_birth }}</q-td>
                    <q-td key="edattain" >{{ props.row.ad_edattain }}</q-td>
                    <q-td key="course" >{{ props.row.ad_course }}</q-td>
                    <q-td key="estrainee" >{{ props.row.ad_estrainee }}</q-td>
                    <q-td key="account" >{{ props.row.ad_account }}</q-td>
                    <q-td key="batch" >{{ props.row.batch }}</q-td>
                    <q-td key="date_hired" >{{ props.row.date_hired }}</q-td>
                    <q-td key="expected_pbdate" >{{ props.row.expected_pbdate }}</q-td>
                    <q-td key="result" >{{ props.row.ad_result }}</q-td>

                    <q-td key="date_of_application">{{ props.row.ad_date_apply }}</q-td>
                    <q-td key="position_applying_for">{{ props.row.ad_position_sought }}</q-td>
                    <q-td key="with_call_center_experience">{{ props.row.ad_ccexperience }}</q-td>

                  </q-tr>
                  <template v-slot:no-data>
                    <div class="full-width row flex-center text-amber-10 q-gutter-sm">
                      <span>
                        No Applications Yet
                      </span>
                      <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
                    </div>
                  </template>
                </q-table>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
    </div>
    <q-dialog v-model="loadingModel" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section class="q-pa-lg">
          <div class="q-ma-md q-mb-xl">
            <div class="items-center">
              <q-spinner-cube color="white" size="5.5em" />
            </div>
          </div>
          <div class="absolute-bottom text-subtitle1 text-center q-mb-sm" style="color: white">
            {{loadingText}}
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="loadingAction" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section class="q-pa-lg">
          <div class="q-ma-md q-mb-xl">
            <div class="items-center">
              <q-spinner-cube color="white" size="5.5em" />
            </div>
          </div>
          <div class="absolute-bottom text-subtitle1 text-center q-mb-sm" style="color: white">
            Processing Application
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="noData">
      <q-card>
        <q-card-section class="row q-pa-sm bg-indigo-6 text-white">
          <q-icon size="1.5em" name="fa fa-exclamation-circle" />
          <span class="q-pl-sm">NO DATA</span>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div class="row q-pa-md">
            <div class="text-h6">There is no data!</div>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog
      v-model="newEmployeeDialog"
      persistent
      :maximized="maximizedToggle"
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card>
        <q-bar>
          <q-space />

          <q-btn dense flat  @click="maximizedToggle = false" :disable="!maximizedToggle">
            <i class="fas fa-window-minimize"></i>
            <q-tooltip v-if="maximizedToggle" content-class="bg-white text-primary">Minimize</q-tooltip>
          </q-btn>

          <q-btn dense flat  @click="maximizedToggle = true" :disable="maximizedToggle">
            <i class="far fa-window-maximize"></i>
            <q-tooltip v-if="!maximizedToggle" content-class="bg-white text-primary">Maximize</q-tooltip>
          </q-btn>

          <q-btn dense flat v-close-popup>
            <i class="fas fa-times"></i>
            <q-tooltip content-class="bg-white text-primary">Close</q-tooltip>
          </q-btn>
        </q-bar>

        <!-- <q-card-section>
          <div class="text-h4">New Employee Information</div>

        </q-card-section> -->

        <q-card-section class="q-mx-xl">
          <q-form ref="vFormEdit" @submit="doneApplication()">
            <div class="row">
              <!-- <div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl q-dialog__inner--fullwidth" style="max-width: 280px" >
                <q-avatar size="200px" rounded  >
                  <img src="../../assets/boy-avatar.png">
                </q-avatar>
              </div>
              <div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl" >

              </div> -->
              <!-- <div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl">

              </div> -->
              <div class="text-h4 col-9">New Employee Information</div>
              <div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md" style="text-align: end;">
                <!-- <q-btn class="" color="red"  style="width: 100px" type="button" @click="deleteEmployee" v-show="canDelete">Delete</q-btn> -->

                <q-btn class="q-mx-sm" color="green" style="width: 100px" type="submit" >Finalize</q-btn>
                <q-btn class="q-mx-sm" color="blue" v-close-popup style="width: 100px" type="button" @click="closeEmployeeModal">Close</q-btn>
              </div>
            </div>
            <br/>
            <div class="row">
              <div class="col-4 col-lg-4 col-xs-12 col-md-6 q-mb-md q-pr-xl q-gutter-md">
                <div style="display: flow-root">
                  <div class="text-h6" style="float: left">Personal Information</div>
                  <q-btn class="q-mx-sm" style="float:right" color="orange" type="button" v-show="generateIdButton" @click="generateEmployeeID">Generate Employee ID</q-btn>
                </div>
                <q-separator/>
                <!-- put required 01132023 -->
                <q-input outlined required v-model="generatedEmployeeIdValue" label="EMPLOYEE NO." :label-color="validateInput(generatedEmployeeIdValue)" class="caps"/>
                <q-input outlined v-model="personalInfo.lastname" label="LASTNAME" :label-color="validateInput(personalInfo.lastname)" class="caps"/>
                <q-input outlined v-model="personalInfo.firstname" label="FIRSTNAME"  :label-color="validateInput(personalInfo.firstname)" class="caps"/>
                <q-input outlined v-model="personalInfo.middlename" label="MIDDLENAME"  :label-color="validateInput(personalInfo.middlename)" class="caps"/>
                <q-input outlined v-model="personalInfo.birthdate" mask="date"  label="DATE OF BIRTH"  :label-color="validateInput(personalInfo.birthdate)" >
                  <template v-slot:append>
                    <i class="far fa-calendar-alt cursor-pointer" >
                      <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="personalInfo.birthdate" @input="() => $refs.qDateProxy.hide()" />
                        </q-popup-proxy>
                      </i>
                    </template>
                  </q-input>
                  <q-select outlined v-model="personalInfo.gender" :options="gender" label="GENDER"  :label-color="validateInput(personalInfo.gender)" />
                  <q-select outlined v-model="personalInfo.civilStat" :options="civilStatus" label="CIVIL STATUS" :label-color="validateInput(personalInfo.civilStat)" />
                  <q-input outlined v-model="personalInfo.contactNo" label="CONTACT NO."  :label-color="validateInput(personalInfo.contactNo)" class="caps"/>
                  <q-input outlined v-model="personalInfo.landline" label="LANDLINE"  :label-color="validateInput(personalInfo.landline)" class="caps"/>
                  <q-input outlined v-model="personalInfo.permanentAdd" label="PERMANENT ADDRESS" :label-color="validateInput(personalInfo.permanentAdd)" class="caps"/>
                  <q-input outlined v-model="personalInfo.presentAdd" label="PRESENT ADDRESS" :label-color="validateInput(personalInfo.presentAdd)" class="caps"/>
                  <br/>
                  <div class="text-h6">Person to Notify in case of Emergency</div>
                  <q-separator/>
                  <q-input outlined v-model="notifyPerson.personToNotif" label="PERSON TO NOTIFY" :label-color="validateInput(notifyPerson.personToNotif)" class="caps"/>
                  <q-input outlined v-model="notifyPerson.personToNotifContact" label="PERSON TO NOTIFY CONTACT" :label-color="validateInput(notifyPerson.personToNotifContact)" class="caps"/>
                  <q-select outlined v-model="notifyPerson.personToNotifRelationship" :options="relationship" label="RELATIONSHIP(Person to Notify)" :label-color="validateInput(notifyPerson.personToNotifRelationship)"/>
                  <q-input outlined v-model="notifyPerson.personToNotifEmailAdd" label="EMAIL ADDRESS(Person to Notify)" :label-color="validateInput(notifyPerson.personToNotifEmailAdd)"/>
              </div>
              <div class="col-4 col-lg-4 col-xs-12 col-md-6 q-mb-md q-pr-xl q-gutter-md">
                <div class="text-h6">Company Information</div>
                <q-separator/>
                <q-select outlined v-model="companyInfo.company" option-value="id" option-label="name" :options="companyOptions" label="COMPANY" emit-value map-options :label-color="validateInput(companyInfo.company)"/>
                  <q-select outlined v-model="companyInfo.is_active"  :options="statuses" label="STATUS" :label-color="companyInfo.is_active == 'ACTIVE' ? 'green' : companyInfo.is_active == 'HOLD' ? 'orange' : 'red'" @input="changeStatus"/>
                  <q-input outlined v-model="companyInfo.holdEffectivity" mask="date"  label="HOLD EFFECTIVITY" v-show="companyInfo.isHold" :label-color="validateInput(companyInfo.holdEffectivity)">
                    <template v-slot:append>
                      <i class="far fa-calendar-alt cursor-pointer" >
                        <q-popup-proxy ref="heDateProxy" transition-show="scale" transition-hide="scale">
                          <q-date v-model="companyInfo.holdEffectivity"  @input="calendarChange"/>
                        </q-popup-proxy>
                      </i>
                    </template>
                  </q-input>
                  <q-input outlined v-model="companyInfo.hold_period"  label="HOLD PERIOD" v-show="companyInfo.isHold"  :label-color="validateInput(companyInfo.hold_period)"/>
                  <q-select outlined v-model="companyInfo.holdReason"  :options="reasons" label="HOLD/SEPARATION REASON" v-show="companyInfo.isHold" :label-color="validateInput(companyInfo.holdReason)"/>
                  <q-input outlined v-model="companyInfo.holdDuration" label="HOLD DURATION" class="caps" v-show="companyInfo.isHold" :label-color="validateInput(companyInfo.holdDuration)"/>

                  <q-input outlined v-model="companyInfo.holdDate" mask="date"  label="HOLD DATE" v-show="companyInfo.isHold"  :label-color="validateInput(companyInfo.holdDate)"/>

                  <q-select outlined v-model="companyInfo.position"  :options="positions" label="POSITION" :label-color="validateInput(companyInfo.position)"/>
                  <q-select outlined v-model="companyInfo.department_id" option-value="id" option-label="name" :options="departmentslist" label="DEPARTMENT" emit-value map-options :label-color="validateInput(companyInfo.department_id)"/>
                  <q-input outlined v-model="personalInfo.email" label="EMAIL ADDRESS" :label-color="validateInput(personalInfo.email)"/>

                  <q-input  outlined v-model="companyInfo.dateHired" mask="date" label="DATE HIRED" :label-color="validateInput(companyInfo.dateHired)">
                    <template v-slot:append>
                      <i class="far fa-calendar-alt cursor-pointer" >
                        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                          <q-date v-model="companyInfo.dateHired" @input="() => $refs.qDateProxy.hide()" />
                          </q-popup-proxy>
                        </i>
                      </template>
                    </q-input>

                    <q-select outlined v-model="companyInfo.account"  :options="accounts" label="ACCOUNT" :label-color="validateInput(companyInfo.account)"/>
                    <q-select outlined v-model="companyInfo.costcenter"  :options="costCenter" label="COST CENTER" :label-color="validateInput(companyInfo.costcenter)"/>
                    <q-select outlined v-model="companyInfo.location"  :options="location" label="LOCATION" :label-color="validateInput(companyInfo.location)"/>
                    <q-select outlined v-model="companyInfo.rank"  :options="rank"  label="RANK" :label-color="validateInput(companyInfo.rank)" @input="employmentStatusOption()"/>
                    <q-select outlined v-model="companyInfo.employmentStatus"  :options="empStatus"  label="EMPLOYMENT STATUS" :label-color="validateInput(companyInfo.employmentStatus)"/>

                    <q-select outlined v-model="companyInfo.immediateHead"  :options="immediateHeadOptions" label="IMMEDIATE HEAD" :label-color="validateInput(companyInfo.immediateHead)"/>
                    <q-select outlined v-model="companyInfo.divisionHead"  :options="divisionHeadOptions" label="DIVISION HEAD" :label-color="validateInput(companyInfo.divisionHead)"/>
              </div>
              <div class="col-4 col-lg-4 col-xs-12 col-md-6 q-mb-md q-pr-xl q-gutter-md">
                <div class="text-h6">Salary Information</div>
                <q-select outlined v-model="governmentInfo.payClass" :options="payclass" label="PAY CLASS" :label-color="validateInput(governmentInfo.payClass)" @input="checkPayclass" v-show="ifConsultant"/>
                <q-select outlined v-model="governmentInfo.region" option-value="id" option-label="region_no" :options="regionOptions" label="REGION" emit-value map-options v-show="isMWE" @input="getSector"/>
                <q-select outlined v-model="governmentInfo.sector" option-value="id" option-label="sector" :options="sectorOptions" label="SECTOR" emit-value map-options v-show="isMWE" @input="getMWEInfo"/>

                    <q-input outlined v-model="salaryInfo.basicSalary"  label="BASIC" :label-color="validateInput(salaryInfo.basicSalary)"   v-show="ifConsultant" @input="calculateTotalSalary()"/>
                    <q-input outlined v-model="salaryInfo.allowance"    label="ALLOWANCE"  :label-color="validateInput(salaryInfo.allowance)"   v-show="ifConsultant" @input="calculateTotalSalary()"/>
                    <q-input outlined v-model="salaryInfo.deminimis"    label="DEMINIMIS"  :label-color="validateInput(salaryInfo.deminimis)"  v-show="ifConsultant" @input="calculateTotalSalary()"/>
                    <q-input outlined v-model="salaryInfo.totalSalary"  label="TOTAL SALARY"  :label-color="validateInput(salaryInfo.totalSalary)"  v-show="ifConsultant"/>

                    <q-select outlined v-model="salaryInfo.paymentMode"  :options="paymentmode" label="MODE OF PAYMENT" :label-color="validateInput(salaryInfo.paymentMode)" v-show="ifConsultant"/>
                    <q-select outlined v-model="salaryInfo.paymentBank"  :options="payingbank" label="PAYING BANK" :label-color="validateInput(salaryInfo.paymentBank)" v-show="ifConsultant"/>
                    <q-input outlined v-model="salaryInfo.accountNumber" label="ACCOUNT/PAYCARD NO." :label-color="validateInput(salaryInfo.accountNumber)" class="caps" v-show="ifConsultant"/>
                    <q-input outlined v-model="salaryInfo.fee" label="Fee" v-show="!ifConsultant" />

                      <br/>
                      <div class="text-h6">Educational Background</div>
                      <q-separator/>
                      <q-select outlined v-model="educationBackground.educationAttainment"  :options="educationalOptions" label="EDUCATIONAL ATTAINMENT" @input="checkEduc" :label-color="validateInput(educationBackground.educationAttainment)"/>
                      <q-select outlined v-model="educationBackground.course" :options="coursesOptions" label="COURSE" v-show="isCGrad" :label-color="validateInput(educationBackground.course)"/>
                      <q-input outlined v-model="educationBackground.school" label="SCHOOL GRADUATED"  v-show="isCGrad" :label-color="validateInput(educationBackground.school)" class="caps"/>
                      <q-input outlined v-model="educationBackground.highschool" label="HIGH SCHOOL" v-show="isHSGrad" :label-color="validateInput(educationBackground.highschool)" class="caps"/>
                          <q-separator/>
              </div>
              <!-- ruben::13/072023 -->
              <div class="col-4 col-lg-4 col-xs-12 col-md-6 q-mb-md q-pr-sm q-gutter-md">
                <div class="text-h6">Government Info</div>
                <q-input outlined v-model="governmentInfo.sssNo" label="SSS #" />
                <q-input outlined v-model="governmentInfo.hdmfNo" label="HDMF #" />
                <q-input outlined v-model="governmentInfo.phicNo" label="PHIC #" />
                <q-input outlined v-model="governmentInfo.tinNo" label="TIN #" />
              </div>
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="confirmModal">
      <q-card style="width: 500px">
        <q-card-section>
          <div class="text-h6">{{ confirm.title }}</div>
        </q-card-section>
        <q-separator />
        <q-card-section class="q-py-sm" style="text-align: center">
          <q-icon name="fas fa-check-circle" style="font-size: 50px;color: green;" v-show="confirm.code == 201"/>
          <q-icon name="fas fa-exclamation-circle" style="font-size: 50px;color: red;" v-show="confirm.code == 1062"/>
          <div class="text-h5">{{ confirm.message }}</div>
        </q-card-section>
        <q-card-actions align="right" class="bg-white text-teal">
          <q-btn flat label="OK" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import axios from 'axios';
//mar_08_2023
//add by ruben
import moment from "moment";
import { date } from 'quasar';
import { exportFile } from 'quasar';
import vueCookies from 'vue-cookies';
function wrapCsvValue (val, formatFn) {
  let formatted = formatFn !== void 0
    ? formatFn(val)
    : val

  formatted = formatted === void 0 || formatted === null
    ? ''
    : String(formatted)

  formatted = formatted.split('"').join('""')
  /**
   * Excel accepts \n and \r in strings, but some other CSV parsers do not
   * Uncomment the next two lines to escape new lines
   */
  // .split('\n').join('\\n')
  // .split('\r').join('\\r')

  return `"${formatted}"`
}

export default {
  name: 'NewApplication',
  data() {
    return {
      filter: '',
      loadingModel: false,
      loadingText: '',
      loadingAction: false,
      applicationCounts: 0,
      applications: [],
      applicationsData: [],
      forHiredSelected: [],
      noData: false,
      dateTo: null,
      dateFrom: null,
      newEmployeeDialog: false,
      maximizedToggle: true,
      searchDateToFrom: {
          dateTo: null,
          dateFrom: null,
          status: 'Endorse'
      },
      Pagination: {
        sortBy: 'date_of_application',
        descending: true,
        // page: 1,
        rowsPerPage: 0
      },
      ResultColumns: [
        {
          name: 'action',
          field: 'action',
          label: 'Action',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px',
        },
        {
          name: 'application_status',
          field: 'application_status',
          label: 'Status',
          sortable: true,
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px',
        },
        {
          name: 'employee_id',
          field: 'employee_id',
          label: 'Employee ID',
          sortable: true,
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px',
        },
        // {
        //   name: 'applicant_id',
        //   field: 'applicant_id',
        //   label: 'Applicant ID',
        //   style: 'width: 200px',
        //   align: 'center',
        //   headerStyle: 'width: 200px',
      
        // },
        {
          name: 'firstname',
          field: 'firstname',
          label: 'First Name',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
        },
        {
          name: 'middlename',
          field: 'middlename',
          label: 'Middle Name',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
        },
        {
          name: 'lastname',
          field: 'lastname',
          label: 'Last Name',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
        },
        {
          name: 'mobile',
          field: 'mobile',
          label: 'Contact Number',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
        },
        {
          name: 'email',
          field: 'email',
          label: 'Email Address',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
        },
        {
          name: 'presaddress',
          field: 'presaddress',
          label: 'Home Address',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
        },
        {
          name: 'prescity',
          field: 'prescity',
          label: 'City',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
        },
        {
          name: 'civil_status',
          field: 'civil_status',
          label: 'Civil Status',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
        },
        {
          name: 'gender',
          field: 'gender',
          label: 'Gender',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
        },
        {
          name: 'date_of_birth',
          field: 'date_of_birth',
          label: 'Birthdate',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
        },
        {
          name: 'edattain',
          field: 'edattain',
          label: 'Highest Educational Attainment',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
        },
        {
          name: 'course',
          field: 'course',
          label: 'Course',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
        },
        {
          name: 'estrainee',
          field: 'estrainee',
          label: 'E.S.',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
        },
        {
          name: 'account',
          field: 'account',
          label: 'Account',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
        },
        {
          name: 'batch',
          field: 'batch',
          label: 'Batch',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
        },
        {
          name: 'date_hired',
          field: 'date_hired',
          label: 'Date Hired',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
        },
        {
          name: 'expected_pbdate',
          field: 'expected_pbdate',
          label: 'Expected PB Date',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
        },
        {
          name: 'result',
          field: 'result',
          label: 'Result',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
        },
        {
          name: 'date_of_application',
          field: 'date_of_application',
          label: 'Date Applied',
          sortable: true,
          align: 'center',
        },
        {
          name: 'position_applying_for',
          field: 'position_applying_for',
          label: 'Position Applying For',
          align: 'center',
        },
        {
          name: 'with_call_center_experience',
          field: 'with_call_center_experience',
          label: 'With Call Center Expereience?',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px',
        },
      ],
      personalInfo:{
      	id:null,
      	employee_no:null,
      	firstname:null,
      	middlename:null,
      	lastname:null,
      	birthdate:null,
      	gender:null,
      	civilStat:null,
      	contactNo:null,
      	landline:null,
      	permanentAdd:null,
      	presentAdd:null,
      	email:null,
      },
      notifyPerson:{
      	personToNotif:null,
      	personToNotifContact:null,
      	personToNotifRelationship:null,
      	personToNotifEmailAdd:null
      },
      educationBackground:{
      	educationAttainment:null,
      	course:null,
      	school:null,
      	highschool:null,
      },
      companyInfo:{
      	is_active:null,
      	isHold:false,
      	company:null,
      	department:null,
      	department_id:null,
      	dateHired:null,
      	position:null,
      	account:null,
      	costcenter:null,
      	location:null,
      	rank:null,
      	employmentStatus:null,
      	hold_period:null,
      	holdReason:null,
      	holdDate:new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
      	holdEffectivity:null,
      	holdDuration:null,
      	immediateHead:null,
      	divisionHead:null,
		    shift_hours:null,
		    period_rate:null,
      },
      previousCompany:{
      	companyName:null,
      	workDuration:null
      },
      salaryInfo:{
      	basicSalary:null,
      	allowance:null,
      	deminimis:null,
      	totalSalary:null,
      	paymentMode:null,
      	paymentBank:null,
      	accountNumber:null,
      	fee:null
      },
      healthInfo:{
      	healthCardName:null,
      	healthCardNo:null,
      	insuranceName:null,
      	insuranceNo:null
      },
      governmentInfo:{
      	sssNo:null,
      	hdmfNo:null,
      	phicNo:null,
      	tinNo:null,
      	taxStatus:null,
      	payClass:null,
      	region:null,
      	sector:null,
      	accountability:[],
      },
      holdPeriodList: [],
      statuses: [],
      reasons: [],
      coursesOptions:[],
      paymentmode: [],
      departmentslist: [],
      payingbank: [],
      payclass: [],
      gender: [],
      civilStatus:[],
      relationship:[],
      accounts:[],
      costCenter:[],
      taxStatus:[],
      location:[],
      rank: [],
	    periodRates:[],
      empStatus:[],
      regionOptions:[],
      sectorOptions:[],
      accountabilities:[],
      positions:[],
      immediateHeadOptions:[],
      divisionHeadOptions:[],
      educationalOptions:[],
      companyOptions:[],
      isHSGrad:false,
      isCGrad:false,
			disabled:false,
			isMWE:false,
			ifConsultant:true,
			spinner:false,
      newCreatedEmployeeId: "",
      generateIdButton: true,
      generatedEmployeeIdValue: null,
      confirmModal: false,
      confirm: {
        title: "",
        message: "",
        code: ""
      },
      viewData: [],
    }
  },
  mounted(){
    this.loadApplications();
    this.getDropdownProperties()
  },
  methods: {
        validateInput:function(value){
          if (value == null || value == '' || value == 'NULL' || value == 'null' || value == "0000-00-00" || value == 0){
            return 'red';
          } else {
            return 'green';
          }
        },
        changeStatus:function(){
          if(this.companyInfo.is_active == 'HOLD'){
            this.companyInfo.isHold = true
          }else{
            this.companyInfo.isHold = false
          }
        },
        checkEduc:function(){
          var educ = this.educationBackground.educationAttainment
          if(educ == 'HIGH SCHOOL'){
            this.isHSGrad = true;
            this.isCGrad = false;
          }else{
            this.isHSGrad = false;
            this.isCGrad = true;
          }
        },
        getDropdownProperties:function(){
          axios.create({
            baseURL : this.apiUrl,
            headers : {'Authorization' : `Bearer ${this.token}`}
          }).get('/employee/dropdown/properties')
          .then((res) => {
            // console.log(res)
            this.gender = res.data.data.gender
            this.paymentmode = res.data.data.paymentMode
            this.payingbank = res.data.data.bank
            this.payclass = res.data.data.payClass
            this.holdPeriodList = res.data.data.holdPeriodList
            this.departmentslist = res.data.data.department
            this.statuses = res.data.data.status
            this.rank = res.data.data.rank
            this.positions = res.data.data.positions
            this.accounts = res.data.data.account
            this.costCenter = res.data.data.costCenter
            this.location = res.data.data.location
            this.civilStatus  = res.data.data.civilStatus
            this.relationship = res.data.data.relationship
            this.taxStatus = res.data.data.taxStatus
            this.empStatus = res.data.data.empStatus
            this.companyOptions = res.data.data.companies
            this.immediateHeadOptions = res.data.data.immediatehead
            this.divisionHeadOptions = res.data.data.divisionhead
            this.coursesOptions = res.data.data.courses

            this.accountabilities = res.data.data.accountability
            this.educationalOptions = res.data.data.educAttaintment

            this.reasons = res.data.data.reasons
            this.periodRates = res.data.data.periodRate
          });
        },
        calendarChange:function(){
          this.$refs.heDateProxy.hide() //close calendar
          var date = this.companyInfo.holdEffectivity.substring(8, 10)
          if(date > 10 && date < 26){
            this.companyInfo.hold_period = '11 to 25'
          }else{
            this.companyInfo.hold_period = '26 to 10'
          }
        },

        calculateTotalSalary:function(){
          var total =  parseInt(this.salaryInfo.basicSalary) + parseInt(this.salaryInfo.allowance) + parseInt(this.salaryInfo.deminimis);
          if(isNaN(total)){
            total = 0;
          }
          this.salaryInfo.totalSalary = parseInt(total);
        },

        checkPayclass:function(){
          var type = this.governmentInfo.payClass

          if(type == 'MONTHLY - MWE' || type == 'DAILY - MWE'){
            this.isMWE = true
            this.displayRegions()
            this.disabled = true
          }else{
            this.disabled = false
            this.isMWE = false
            this.salaryInfo.basicSalary = 0
            this.salaryInfo.allowance = 0
            this.salaryInfo.deminimis = 0
            this.salaryInfo.totalSalary = 0
          }
        },

        displayRegions: function() {
          axios.create({
            baseURL: this.apiUrl,
            headers: {
              'Authorization': `Bearer ${this.token}`
            }})
          .get(`/admin/regions`)
          .then((res) => {

            this.regionOptions = res.data.data;
            setTimeout(() => {
              this.spinner = false
            },500)
          })
        },

        getSector:function(){
          axios.create({
            baseURL: this.apiUrl,
            headers: {
              'Authorization': `Bearer ${this.token}`
            }})
          .get(`/admin/region/sectors/${this.governmentInfo.region}`)
          .then((res) => {
            this.governmentInfo.sector = ''
            this.salaryInfo.basicSalary = ''
            this.salaryInfo.allowance = ''
            this.salaryInfo.deminimis = ''
            this.salaryInfo.totalSalary = ''
            this.sectorOptions = res.data.data;
            setTimeout(() => {
              this.spinner = false
            },500)
          })
        },

        getMWEInfo:function(){
          axios.create({
            baseURL: this.apiUrl,
            headers: {
              'Authorization': `Bearer ${this.token}`
            }})
          .get(`/admin/check/mwe/${this.governmentInfo.region}/${this.governmentInfo.sector}/${this.governmentInfo.payClass}`)
          .then((res) => {
            if(res.data.data != null){
              this.salaryInfo.basicSalary = res.data.data.basic
              this.salaryInfo.allowance = res.data.data.allowance
              this.salaryInfo.deminimis = res.data.data.deminimis
              this.salaryInfo.totalSalary = res.data.data.total
            }else{
              this.salaryInfo.basicSalary = 0
              this.salaryInfo.allowance = 0
              this.salaryInfo.deminimis = 0
              this.salaryInfo.totalSalary = 0
            }

          })
        },

        employmentStatusOption:function(){
          var ranks = this.companyInfo.rank.toLowerCase();
          if(ranks === 'trainee' || ranks === 'consultant'){
            this.companyInfo.employmentStatus = this.companyInfo.rank
          }

          if(ranks === 'trainee'){
            this.isTrainee = false;
            var traineeID = this.personalInfo.employee_no.replace('SGCC','TRAI')
            this.personalInfo.employee_no = traineeID
          }else{
            this.isTrainee = true;
            var empID = this.personalInfo.employee_no.replace('TRAI','SGCC')
            this.personalInfo.employee_no = empID
          }

          if(ranks === 'consultant'){
            this.ifConsultant = false;
            this.isTrainee = false;
          }else{
            this.ifConsultant = true;
          }
        },
        searchData: function(){
            vueCookies.set(this.searchKey, {
            'search': this.filter
            });
            //this.filter = this.searchKeyword;
        },

        loadApplications: function() {
            this.loadingText = 'Retrieving Applications';
            this.loadingModel = true;
            this.applications = [];
            // this.applicationsData = [];

          axios.create({
            baseURL: this.apiUrl,
            headers: {
                'Authorization': `Bearer ${this.token}`
            }})
            .get('/employee/complete-application')
            .then((res) => {
                console.log("loadapp: ",res.data.data)
                this.applicationsData = res.data.data;
                this.applicationCounts = Object.keys(this.applicationsData).length;

            }).finally(() => {
                this.loadingModel = false;
                this.loadingText = '';
            });
        },
        openModal(id){
          var data = {
            id: id,
          }
          this.applications = [];
          let formData = new FormData();
          this.loadingModel = true;
          this.loadingText = 'Retrieving Information';
          formData.append('form_data', JSON.stringify(data));
          axios.create({
              baseURL: this.apiUrl,
              headers: {
              'Authorization': `Bearer ${this.token}`
              }
          }).post('/employee/get-finalization-application', formData).then((res) => {

                // console.log(res.data.data)
                //this.applicationsData = res.data.data;
                this.applications.push(res.data.data[0].applicant_id);
                this.viewData = res.data.data[0];

                var edattn = '';
                if(this.viewData.ad_edattain == "ed0"){
                    edattn = "High School Graduate";
                } else if(this.viewData.ad_edattain == "ed1"){
                    edattn = "College Level";
                } else if(this.viewData.ad_edattain == "ed2"){
                    edattn = "Degree Holder";
                } else if(this.viewData.ad_edattain == "ed3"){
                    edattn = "Vocational Graduate";
                }else {
                    edattn = this.viewData.ad_edattain;
                }

              // PERSONAL INFORMATION
                this.personalInfo.id = this.viewData.emp_keyid;
                this.personalInfo.employee_no = this.viewData.employee_id;
                this.personalInfo.firstname = this.viewData.ad_firstname;
                this.personalInfo.middlename = this.viewData.ad_middlename;
                this.personalInfo.lastname = this.viewData.ad_lastname;
                this.personalInfo.gender = this.viewData.ad_gender;
                this.personalInfo.landline = this.viewData.ad_telephone;
                this.personalInfo.birthdate = this.viewData.ad_date_of_birth;
                this.personalInfo.civilStat = this.viewData.ad_civil_status;
                this.personalInfo.contactNo = this.viewData.ad_mobile;
                this.personalInfo.permanentAdd = this.viewData.ad_presaddress;
                this.personalInfo.presentAdd = this.viewData.ad_prescity;
                this.personalInfo.email = this.viewData.ad_email;

                // this.notifyPerson.personToNotif = this.viewData.ad_person_to_notif;
                // this.notifyPerson.personToNotifContact = this.viewData.ad_person_to_notif_contact;
                // this.notifyPerson.personToNotifRelationship = this.viewData.ad_relation_to_person_to_notif;
                // this.notifyPerson.personToNotifEmailAdd = this.viewData.ad_email_of_person_to_notif;


                //ruben::12/07/2023
                this.notifyPerson.personToNotif = this.viewData.applicant_details.contact_name;
                this.notifyPerson.personToNotifContact = this.viewData.applicant_details.contact_number;
                this.notifyPerson.personToNotifRelationship = this.viewData.applicant_details.contact_relation;
                this.notifyPerson.personToNotifEmailAdd = this.viewData.applicant_details.email;

                this.educationBackground.educationAttainment = edattn;
                this.educationBackground.course = this.viewData.ad_college_course;
                this.educationBackground.school = this.viewData.ad_college_school;
                this.educationBackground.highschool = this.viewData.ad_high_school;

              // available companyInfo
                this.companyInfo.company = "STERLING GLOBAL CALL CENTER INC.";
                this.companyInfo.account = this.viewData.account;
                this.companyInfo.is_active = "ACTIVE";
                this.companyInfo.dateHired = this.viewData.pb_date;
                this.companyInfo.rank = "RANK AND FILE";
                this.companyInfo.employmentStatus = this.viewData.employee_status;
                this.companyInfo.position = this.viewData.position;

                //ruben::13-06-2023
                this.companyInfo.location = this.viewData.location
                this.salaryInfo.deminimis = this.viewData.deminimis

                if(this.companyInfo.rank === 'TRAINEE'){
                  this.isTrainee = false;

                }else{
                  this.isTrainee = true;
                }

                this.governmentInfo.payClass = "MONTHLY";

                this.salaryInfo.basicSalary = this.viewData.basic_salary;
                this.salaryInfo.allowance = this.viewData.ad_allowance;

                // ruben::13/07/2023
                this.governmentInfo.sssNo = this.viewData.sss
                this.governmentInfo.tinNo = this.viewData.tin
                this.governmentInfo.hdmfNo = this.viewData.hdmf
                this.governmentInfo.phicNo = this.viewData.phic
          }).finally(() => {
              this.loadingModel = false;
              this.loadingText = '';
          });
          this.newEmployeeDialog = true;
        },
        doneApplication(){

          let formData = new FormData();
          //mar_08_2023
          //add by ruben
          let bday = moment(this.personalInfo.birthdate).format('YYYY-MM-DD');
          let date_hired = moment(this.companyInfo.dateHired).format('YYYY-MM-DD');
          formData.append("id", this.applications[0]);
          formData.append("employee_id", this.generatedEmployeeIdValue);
          formData.append("firstname", this.personalInfo.firstname);
          formData.append("middlename", this.personalInfo.middlename);
          formData.append("lastname", this.personalInfo.lastname);
          formData.append("email", this.personalInfo.email);
          formData.append("date_of_birth", bday);

          formData.append("gender", (this.personalInfo.gender.toLowerCase() == 'male') ? 'M' : 'F' );
          formData.append("civil_status", this.personalInfo.civilStat);
          formData.append("mobile_number", this.personalInfo.contactNo);
          formData.append("landline_number", this.personalInfo.landline);
          formData.append("address", this.personalInfo.permanentAdd);
          formData.append("city_address", this.personalInfo.presentAdd);

          formData.append("person_to_notif", this.notifyPerson.personToNotif);
          formData.append("person_to_notif_contact", this.notifyPerson.personToNotifContact);
          formData.append("relation_to_person_to_notif", this.notifyPerson.personToNotifRelationship);
          formData.append("email_of_person_to_notif", this.notifyPerson.personToNotifEmailAdd);

          formData.append("educational_attainment", this.educationBackground.educationAttainment);
          formData.append("college_course", this.educationBackground.course);
          formData.append("college_school", this.educationBackground.school);
          formData.append("high_school", this.educationBackground.highschool);

          formData.append("date_hired", date_hired);
          formData.append("company", this.companyInfo.company);
          formData.append("department", this.companyInfo.department_id);
          formData.append("position", this.companyInfo.position);
          formData.append("account", this.companyInfo.account);
          formData.append("cost_center", this.companyInfo.costcenter);
          formData.append("location", this.companyInfo.location);
          formData.append("rank", this.companyInfo.rank);
          formData.append("employment_status", this.companyInfo.employmentStatus);

          formData.append("is_active", this.companyInfo.is_active);
          formData.append("hold_effectivity", this.companyInfo.holdEffectivity);
          formData.append("hold_period", this.companyInfo.hold_period);
          formData.append("hold_reason", this.companyInfo.holdReason);
          formData.append("hold_duration", this.companyInfo.holdDuration);
          formData.append("hold_date", this.companyInfo.holdDate);


          formData.append("immediate_head", this.companyInfo.immediateHead);
          formData.append("division_head", this.companyInfo.divisionHead);

          formData.append("previous_company", this.previousCompany.companyName);
          formData.append("previous_company_length_of_stay", this.previousCompany.workDuration);

          formData.append("basic_salary", this.salaryInfo.basicSalary);
          formData.append("allowance", this.salaryInfo.allowance);
          formData.append("deminimis", this.salaryInfo.deminimis);
          formData.append("total_salary", this.salaryInfo.totalSalary);

          formData.append("payment_mode", this.salaryInfo.paymentMode);
          formData.append("paying_bank", this.salaryInfo.paymentBank);
          formData.append("account_number", this.salaryInfo.accountNumber);

          formData.append("healthcard_name", this.healthInfo.healthCardName);
          formData.append("healthcard_number", this.healthInfo.healthCardNo);
          formData.append("insurance_name", this.healthInfo.insuranceName);
          formData.append("insurance_number", this.healthInfo.insuranceNo);

          //formData.append("sss_number", this.governmentInfo.sssNo);
          //formData.append("hdmf_number", this.governmentInfo.hdmfNo);
          //formData.append("philhealth_number", this.governmentInfo.phicNo);
          //formData.append("tin_number", this.governmentInfo.tinNo);
          formData.append("bir_number", this.governmentInfo.tinNo);
          formData.append("tax_status", this.governmentInfo.taxStatus);
          formData.append("pay_class", this.governmentInfo.payClass);
          formData.append("regionID", this.governmentInfo.region);
          formData.append("sectorID", this.governmentInfo.sector);
          formData.append("accountability", this.governmentInfo.accountability);
          formData.append("shift_hours", this.companyInfo.shift_hours);
          formData.append("period_rate", this.companyInfo.period_rate);

          // ruben::13/07/2023
          formData.append("sss_number", this.governmentInfo.sssNo);
          formData.append("hdmf_number", this.governmentInfo.hdmfNo);
          formData.append("philhealth_number", this.governmentInfo.phicNo);
          formData.append("tin_number", this.governmentInfo.tinNo);

          axios.create({
              baseURL: this.apiUrl,
              headers: {
              'Authorization': `Bearer ${this.token}`
              }
          }).post('/employee/endorse-create-application', formData).then((res) => {
             this.$q.loading.show()
              setTimeout(() => {
              //console.log("FINALIZEEE CLICKED; ", res);
              var data = res.data.data;
              if(res.data.status === true){
                this.personalInfo.id = data.sub_emp_id;

                this.confirm.title = "Employee";
                this.confirm.message = res.data.message;
                this.confirm.code = 201;
                // alert(res.data.statusCode);
                this.confirmModal = true;

                this.newEmployeeDialog = false;
                this.updateEndorseApplicants(data.applicant_id, data.sub_emp_id);
              }else{
                // this.newEmployeeDialog = false;
                console.log(res.data)

                this.confirm.title = "Error";
                this.confirm.message = res.data.message;
                this.confirm.code = 1062;
                // alert(res.data.statusCode);
                this.confirmModal = true;
                this.generatedEmployeeIdValue = "";
                this.generateIdButton = true;
                this.loadApplications();
              }
              //mar_08_2023
              //add by ruben
              this.$q.loading.hide()
              }, 1000);
          });
        },
        updateEndorseApplicants(appid, id){
          let formData = new FormData();

          var data = {
            'keyid': id,
            'applicant_id': appid
          }

          formData.append('form_data', JSON.stringify(data));
          axios.create({
              baseURL: this.apiUrl,
              headers: {
              'Authorization': `Bearer ${this.token}`
              }
          }).post('/employee/update_endorse_applicants', formData).then((res) => {
              setTimeout(() => {
              console.log("update_keyid: ", res);
              this.newEmployeeDialog = false;
              this.loadApplications();
              }, 1000);
          });
        },
        onSubmit : function(){

          let formData = new FormData();
          formData.append("id", this.personalInfo.id);
          formData.append("employee_id", this.generatedEmployeeIdValue);
          formData.append("firstname", this.personalInfo.firstname);
          formData.append("middlename", this.personalInfo.middlename);
          formData.append("lastname", this.personalInfo.lastname);
          formData.append("email", this.personalInfo.email);
          formData.append("date_of_birth", this.personalInfo.birthdate);

          formData.append("gender", this.personalInfo.gender);
          formData.append("civil_status", this.personalInfo.civilStat);
          formData.append("mobile_number", this.personalInfo.contactNo);
          formData.append("landline_number", this.personalInfo.landline);
          formData.append("address", this.personalInfo.permanentAdd);
          formData.append("city_address", this.personalInfo.presentAdd);

          formData.append("person_to_notif", this.notifyPerson.personToNotif);
          formData.append("person_to_notif_contact", this.notifyPerson.personToNotifContact);
          formData.append("relation_to_person_to_notif", this.notifyPerson.personToNotifRelationship);
          formData.append("email_of_person_to_notif", this.notifyPerson.personToNotifEmailAdd);

          formData.append("educational_attainment", this.educationBackground.educationAttainment);
          formData.append("college_course", this.educationBackground.course);
          formData.append("college_school", this.educationBackground.school);
          formData.append("high_school", this.educationBackground.highschool);

          formData.append("date_hired", this.companyInfo.dateHired);
          formData.append("company", this.companyInfo.company);
          formData.append("department", this.companyInfo.department_id);
          formData.append("position", this.companyInfo.position);
          formData.append("account", this.companyInfo.account);
          formData.append("cost_center", this.companyInfo.costcenter);
          formData.append("location", this.companyInfo.location);
          formData.append("rank", this.companyInfo.rank);
          formData.append("employment_status", this.companyInfo.employmentStatus);

          formData.append("is_active", this.companyInfo.is_active);
          formData.append("hold_effectivity", this.companyInfo.holdEffectivity);
          formData.append("hold_period", this.companyInfo.hold_period);
          formData.append("hold_reason", this.companyInfo.holdReason);
          formData.append("hold_duration", this.companyInfo.holdDuration);
          formData.append("hold_date", this.companyInfo.holdDate);


          formData.append("immediate_head", this.companyInfo.immediateHead);
          formData.append("division_head", this.companyInfo.divisionHead);

          formData.append("previous_company", this.previousCompany.companyName);
          formData.append("previous_company_length_of_stay", this.previousCompany.workDuration);

          formData.append("basic_salary", this.salaryInfo.basicSalary);
          formData.append("allowance", this.salaryInfo.allowance);
          formData.append("deminimis", this.salaryInfo.deminimis);
          formData.append("total_salary", this.salaryInfo.totalSalary);

          formData.append("payment_mode", this.salaryInfo.paymentMode);
          formData.append("paying_bank", this.salaryInfo.paymentBank);
          formData.append("account_number", this.salaryInfo.accountNumber);

          formData.append("healthcard_name", this.healthInfo.healthCardName);
          formData.append("healthcard_number", this.healthInfo.healthCardNo);
          formData.append("insurance_name", this.healthInfo.insuranceName);
          formData.append("insurance_number", this.healthInfo.insuranceNo);

          //formData.append("sss_number", this.governmentInfo.sssNo);
          //formData.append("hdmf_number", this.governmentInfo.hdmfNo);
          //formData.append("philhealth_number", this.governmentInfo.phicNo);
          //formData.append("tin_number", this.governmentInfo.tinNo);
          formData.append("bir_number", this.governmentInfo.tinNo);
          formData.append("tax_status", this.governmentInfo.taxStatus);
          formData.append("pay_class", this.governmentInfo.payClass);
          formData.append("regionID", this.governmentInfo.region);
          formData.append("sectorID", this.governmentInfo.sector);
          formData.append("accountability", this.governmentInfo.accountability);
          formData.append("shift_hours", this.companyInfo.shift_hours);
          formData.append("period_rate", this.companyInfo.period_rate);

          axios.create({
                baseURL: this.apiUrl,
                headers: {
                'Authorization': `Bearer ${this.token}`
                }
            }).post('/employee/endorse-create-application', formData).then((res) => {
                setTimeout(() => {
                // console.log("all ids;", res);
                var data = res.data.data;
                this.personalInfo.id = data.sub_emp_id;
                this.updateEndorseApplicants(data.applicant_id, data.sub_emp_id);

                }, 1000);
            });

        },
        closeEmployeeModal(){
          this.generatedEmployeeIdValue = "";
          this.generateIdButton = true;
        },
        generateEmployeeID(){
          axios.create({
            baseURL: this.apiUrl,
            headers: {
              'Authorization': `Bearer ${this.token}`
            }})
          .post(`/employee/generate-employee-id`)
          .then((res) => {
              console.log(res.data);
              this.generatedEmployeeIdValue = res.data;
              this.generateIdButton = false;
          })
        },
        // saveToData(eachApplication){
        //   // this.applicationsData = [];
        //   console.log("savetodata: ", eachApplication)
        //   let name = eachApplication.applicant_details.lastname + ', ' + eachApplication.applicant_details.firstname + ' ' + eachApplication.applicant_details.middlename;
        //   let tempDate = eachApplication.applicant_details.date_apply;
        //   let date_applied = date.formatDate(tempDate, 'MMMM DD, YYYY');
        //   let date_exam = date.formatDate(eachApplication.applicant_details.exam_date, 'MMMM DD, YYYY');

        //   var status = "";
        //   if(eachApplication.applicant_details.passfail == 'Pass'){
        //       status = "For Final Interview";
        //   }else if(eachApplication.applicant_details.passfail == 'Fail'){
        //       status = "Delete";
        //   }else{
        //       status = "No results";
        //   }

        //   var edattn = '';
        //   if(eachApplication.applicant_details.edattain == "ed0"){
        //       edattn = "High School Graduate";
        //   } else if(eachApplication.applicant_details.edattain == "ed1"){
        //       edattn = "College Level";
        //   } else if(eachApplication.applicant_details.edattain == "ed2"){
        //       edattn = "Degree Holder";
        //   } else if(eachApplication.applicant_details.edattain == "ed3"){
        //       edattn = "Vocational Graduate";
        //   }else {
        //       edattn = eachApplication.applicant_details.edattain;
        //   }


        // // PERSONAL INFORMATION
        //   this.personalInfo.id = eachApplication.emp_keyid;
        //   this.personalInfo.employee_no = eachApplication.employee_id;
        //   this.personalInfo.firstname = eachApplication.applicant_details.firstname;
        //   this.personalInfo.middlename = eachApplication.applicant_details.middlename;
        //   this.personalInfo.lastname = eachApplication.applicant_details.lastname;
        //   this.personalInfo.gender = eachApplication.applicant_details.gender;
        //   this.personalInfo.landline = eachApplication.applicant_details.telephone;
        //   this.personalInfo.birthdate = eachApplication.applicant_details.date_of_birth;
        //   this.personalInfo.civilStat = eachApplication.applicant_details.civil_status;
        //   this.personalInfo.contactNo = eachApplication.applicant_details.mobile;
        //   this.personalInfo.permanentAdd = eachApplication.applicant_details.presaddress;
        //   this.personalInfo.presentAdd = eachApplication.applicant_details.prescity;
        //   this.personalInfo.email = eachApplication.applicant_details.email;

        //   this.notifyPerson.personToNotif = eachApplication.applicant_details.person_to_notif;
        //   this.notifyPerson.personToNotifContact = eachApplication.applicant_details.person_to_notif_contact;
        //   this.notifyPerson.personToNotifRelationship = eachApplication.applicant_details.relation_to_person_to_notif;
        //   this.notifyPerson.personToNotifEmailAdd = eachApplication.applicant_details.email_of_person_to_notif;

        //   this.educationBackground.educationAttainment = eachApplication.applicant_details.educational_attainment;
        //   this.educationBackground.course = eachApplication.applicant_details.college_course;
        //   this.educationBackground.school = eachApplication.applicant_details.college_school;
        //   this.educationBackground.highschool = eachApplication.applicant_details.high_school;


        // // available companyInfo
        //   this.companyInfo.company = "STERLING GLOBAL CALL CENTER INC.";
        //   this.companyInfo.account = eachApplication.account;
        //   this.companyInfo.is_active = "ACTIVE";
        //   this.companyInfo.dateHired = eachApplication.pb_date;
        //   this.companyInfo.rank = "RANK AND FILE";
        //   this.companyInfo.employmentStatus = eachApplication.employee_status;
        //   this.companyInfo.position = eachApplication.position;


        //   if(this.companyInfo.rank === 'TRAINEE'){
        //     this.isTrainee = false;

        //   }else{
        //     this.isTrainee = true;
        //   }

        //   this.governmentInfo.payClass = "MONTHLY";

        //   this.salaryInfo.basicSalary = eachApplication.basic_salary;
        //   this.salaryInfo.allowance = eachApplication.applicant_details.allowance;


        // }
    },
}
</script>
<style scoped>
.sticky {
  height: 80px;
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  top: 0;
  z-index: 10;
}

.sticky div {
  width: 100%;
}

.btn-height {
  height: 40px;
}

.text-head {
  font-size: 16px;
}
</style>
