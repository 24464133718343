<template>
  <div id="app">
    <router-view class="roboto" />
      <q-dialog v-model="showModal" persistent>
        <q-card style="min-width: 350px">
          <q-card-section>
            <div class="text-h6">You do not have permissions to access this page. Please contact the administrator.</div>
          </q-card-section>
          <q-card-actions align="right" class="text-primary">
            <q-btn flat label="Logout" v-close-popup @click="logOut()" />
          </q-card-actions>
        </q-card>
      </q-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import vueCookies from 'vue-cookies';
import { ref } from 'vue'

export default {
  name: 'App',
  data() {
    return {
      isLoggedIn: false,
      showModal: ref(false),
    };
  },
  
  created() {
    if(vueCookies.isKey('sgcchris')){
      this.isLoggedIn = true;
    }

    if(this.isLoggedIn){
      this.checkPermissions();
    }
  },

  methods: {
    checkPermissions() {
      axios.create({ baseURL: this.apiUrl, headers: { 'Authorization': `Bearer ${this.token}` }
        }).get('/check-permissions').then((res) => {
          if(res.data.data !== true){
            this.showModal = ref(true);
          }
          console.log('permission: ' + res.data.data);
      }).catch((err) => {
        this.showModal = ref(true);
        console.log(err);
      });
    },

    logOut: function() {
      vueCookies.remove(this.cookieKey);
      setTimeout(() => {
        location.reload();
        this.loading = false
      }, 1000);
    }
  },
};
</script>

<style>
@import url( 'https://fonts.googleapis.com/css2?family=Roboto&display=swap'); 
a {
  text-decoration: none;
}
.roboto {
  font-family: "Roboto", sans-serif;
}

</style>