<template>
    <div>
        <div class="q-gutter-md row justify-start q-carousel__navigation-inner row items-center">
            <p style="color: #564254; font-size: 22px; font-weight: bold; padding: 20px;">E-Learning</p>
            <q-btn-dropdown v-if="isAdmin" color="primary" label="Add Video" style="margin-top: -5px;">
                <q-list>
                    <q-item clickable v-close-popup @click="onItemClick">
                    <q-item-section>
                        <q-item-label>Upload Video</q-item-label>
                    </q-item-section>
                    </q-item>

                    <q-item clickable v-close-popup @click="prompt = true"> 
                    <q-item-section>
                        <q-item-label>Add Youtube Link</q-item-label>
                    </q-item-section>
                    </q-item>
                </q-list>
            </q-btn-dropdown>

            <q-dialog v-model="prompt">
                <q-card style="min-width: 350px">
                    <q-card-section>
                    <div class="text-h6">Enter Video Details</div>
                    </q-card-section>

                    <q-card-section class="q-pt-none" style="margin-bottom: 10px;">
                        <q-input dense v-model="lesson_name" autofocus @keyup.enter="prompt = false" label="Title"/>
                    </q-card-section>

                    <q-card-section class="q-pt-none">
                        <q-input dense v-model="link" autofocus @keyup.enter="prompt = false" label="Youtube Embed Link"/>
                    </q-card-section>

                    <q-card-section class="q-pt-none">
                        <q-input dense v-model="details" autofocus @keyup.enter="prompt = false" label="Video Details"/>
                    </q-card-section>

                    <q-card-actions align="right" class="text-primary">
                    <q-btn flat label="Cancel" v-close-popup @click="lesson_name = ''; link = ''; details = '';" />
                    <q-btn flat label="Add Video" v-close-popup @click="createLesson()" />
                    </q-card-actions>
                </q-card>
            </q-dialog>
        </div>
        <div class="center">
            <main v-for="lesson in lessons" :key="lesson.id" class="container1">
                <div class="container2">
                    <iframe class="video" width="400" height="240" frameBorder="0" allow="fullscreen;" :src="lesson.link"></iframe>
                </div>
                <div style="margin-top: 5px; font-size: 16px; font-weight: 500;">
                    <p class="text">{{ lesson.lesson_name }}</p>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import vueCookies from 'vue-cookies';

export default {
	data () {
		return {
           lessons: [],
           prompt: false,
           lesson_name: '',
           link: '',
           details: '',
           isAdmin: false,
	    }
	},

	created(){
		this.getLessons()
        this.checkPermissions()
	},
	methods: {
        checkPermissions(){
            if(vueCookies.get('sgcchris').user.role_id === 1){
                this.isAdmin = true;
            }
        },

	    getLessons() {
	        axios.get(this.apiUrl + '/lessons', {
	            headers: { 'Authorization': `Bearer ${this.token}` },
	        }).then((response) => {
	            this.lessons = response.data.data
            	console.log('data:' + JSON.stringify(this.lessons))
				this.loading = false
	        }).catch((error) => {
	            console.log(error);
	        })
	   },

       createLesson() {
            let formData = new FormData();
            formData.append("lesson_name", this.lesson_name);
            formData.append("link", this.link);
            formData.append("details", this.details);

            axios.create({
                baseURL: this.apiUrl,
                headers: { 'Authorization': `Bearer ${this.token}` }
            }).post('/create/lesson', formData)
            .then((res) => {
                this.lesson_name = ''
                this.link = ''
                this.details = ''
                this.getLessons()
                this.$q.notify({
                    progress: true,
                    message: `Video Added Successfully.`,
                    color: 'green'
                }, 2000)
            }).catch((error) => {
                this.lesson_name = ''
                this.link = ''
                this.details = ''
                this.$q.notify({
                    progress: true,
                    message: `Something went wrong.`,
                    color: 'red'
                }, 2000)
                console.log(error)
            });
        },
	}
	}
</script>

<style scoped>
.center{
    display: flex;
    align-content: center;
    justify-content: start;
    width: 100%;
    gap: 50px;
    padding-left: 50px;
    padding-right: 50px;
    flex-wrap: wrap;
}

.container1 {
    width: 400px;
    height: 240px;
}

.container2 {
    width: 400px;
    height: 240px;
    background: black;
    display: block;
    overflow: hidden;
    border-radius: 25px;
    transform: translateZ(0px);
    position: relative;
}

.text {
    text-align: center;
    color: black;
}
</style>